.banner {
    position: relative;
    overflow: hidden;
    background: url(../../../public/assets/images/cover.JPG) no-repeat;
    min-height: 550px;
    background-repeat: no-repeat;
    background-color: #b4bcc9;
    background-size: cover;
}

.blur {
    backdrop-filter: blur(1px) saturate(100%);
    -webkit-backdrop-filter: blur(1px) saturate(100%);
    background-color: rgba(39, 39, 39, 0.15);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.title {
    font-size: 3.75rem;
    line-height: 1.2;
    letter-spacing: -1.2px;
    text-transform: capitalize;
}

@media(max-width:576px) {
    .title {
        font-size: 2.75rem;
    }

    .banner {
        min-height: 500px;
    }
}



.text {
    font-weight: 500;
}

.margin {
    margin-top: 8rem;
    position: relative;
}

@media(max-width:576px) {
    .margin {
        margin-top: 8rem;
    }
}