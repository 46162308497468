.feature-item {
    background-color: #fff;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0px 0px 30px 0px rgba(0, 42, 106, 0.1);
    height: auto;
}

@media (min-width: 768px) {
    .feature-item {
        min-height: 14.6875rem;
    }
}

@media (min-width: 992px) {
    .feature-item {
        min-height: 27rem;
    }
}

@media (min-width: 1200px) {
    .feature-item {
        min-height: 22.625rem;
    }
}

.feature-description,
.description {
    line-height: 1.875rem;
}

.feature-description i {
    margin-right: 5px;
}

.feature-title {
    color: var(--five-color);
    padding-bottom: 0.5rem;
    position: relative;
    display: inline-block;
    border-bottom: 4px solid var(--grey-0);
    transition: 0.3s;
}

.feature-item:hover .feature-title {
    border-bottom: 4px solid var(--first-color);
}



.service-item {
    background: #fff;
    border-radius: 5px;
    transition: .5s;
}

.service-item:hover {
    transform: scale(1.05);
}

.service-title {
    color: var(--five-color);
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
}

.service-title:hover {
    color: var(--first-color);
}

.description {
    line-height: 1.875rem;
    font-size: 1.125rem;
}