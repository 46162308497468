.navbar {
    background-color: var(--primary-color);
}

.navbar-nav a.nav-link {
    font-weight: 600;
    color: var(--five-color);
    transition: all .25s ease;
}

.navbar-nav a.nav-link:hover {
    color: var(--first-color);
    transition: all .25s ease;
}

.dropdown-menu {
    border-top: 5px solid var(--first-color) !important;
    border-radius: 0 !important;
    box-shadow: var(--shadow-sm) !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: transparent !important;
    color: var(--first-color) !important;
}

.logo {
    width: 15.625rem;
}

@media (max-width: 768px) {
    .logo {
        width: 12.5rem;
    }
}