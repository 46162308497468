@import "~bootstrap-icons/font/bootstrap-icons";

:root {
  --first-color: #E03423;
  --second-color: #FDFFFC;
  --third-color: #235789;
  --four-color: #F1D302;
  --five-color: #020100;
  --text-color: #6F8BA4;
  --grey-0: #eeeeee;
  --shadow-sm: 0 .125rem .25rem rgba(0, 0, 0, .075)
}

@font-face {
  font-family: myFont;
  src: url(../public/assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
}

body {
  color: var(--five-color);
  font-family: "myFont", Roboto;
}

.first-color {
  color: var(--first-color);
}

.bg-first-color {
  background-color: var(--first-color);
  border-color: var(--first-color);
}

.bg-first-color:hover,
.bg-first-color:focus,
.bg-first-color:active {
  background-color: var(--first-color) !important;
  border-color: var(--first-color) !important;
}

.section-pad {
  padding: 3.25rem 0;
}

.section-title {
  color: var(--five-color);
  padding-bottom: 1.25rem;
  font-weight: 400;
  position: relative;
}

.section-title:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--first-color);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bg-color {
  background-color: var(--grey-0);
}