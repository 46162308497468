.product-banner {
    background: url(../../../public/assets/images/product-banner.jpg) no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    padding: 100px 0;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 1.2rem;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-details {
    font-size: 1.15rem;
    line-height: 1.8;
}

.product-title {
    display: block;
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
}

.product-title:after {
    content: '';
    width: 65px;
    height: 3px;
    position: absolute;
    background: var(--first-color);
    bottom: 0;
    left: 0;
}

.product-text {}

.product-specs {}