.back-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: var(--first-color);
    color: #fff;
    border-radius: 10px;
    height: 50px;
    width: 50px;
    text-align: center;
    padding-top: 13px;
    cursor: pointer;
}