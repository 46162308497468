.sub-menu-container {
    line-height: 1.8;
    background-color: var(--grey-0);
    padding: 0 1rem;
}

.sub-menu {
    margin: 0;
    list-style: none;
    padding: 0.25rem 0;
}

.sub-menu li {}

.sub-menu li a {
    text-decoration: none;
    color: var(--five-color);
    padding: .85rem 0;
    border-bottom: 1px solid;
    display: block;
    transition: 0.3s;
}

.sub-menu li a:last-child {
    border: 0;
}

.sub-menu li a:hover {
    text-decoration: none;
    color: var(--first-color);
}

.sub-menu li a i {
    margin-right: 5px;
}