.service-content {
    font-size: 1.15rem;
    line-height: 1.8;
}

.question::first-letter {
    text-transform: uppercase;
}

.product-title {
    display: block;
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
}

.product-title:after {
    content: '';
    width: 65px;
    height: 3px;
    position: absolute;
    background: var(--first-color);
    bottom: 0;
    left: 0;
}